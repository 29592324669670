import React, { useEffect, useState, useRef } from "react"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Breadcrumb from "src/components/Breadcrumb"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import { graphql } from "gatsby"

import "src/assets/less/homepage.less"

const generateMonthYearSlugs = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate.getTime());  // Clone date to avoid mutating original

  while (currentDate <= endDate) {
    const month = currentDate.toLocaleString('en-us', { month: 'short' }).toLowerCase();
    const year = currentDate.getFullYear();
    dates.push(`${month}-${year}`);
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return dates;
};

const groupSlugsByYear = (slugs) => {
  const grouped = {};
  slugs.forEach(slug => {
    const [month, year] = slug.split('-');
    if (!grouped[year]) {
      grouped[year] = [];
    }
    grouped[year].push({ month, slug });
  });
  return grouped;
};

const shortMonthToLong = (month) => {
  const map = {
    'jan': 'January',
    'feb': 'February',
    'mar': 'March',
    'apr': 'April',
    'may': 'May',
    'jun': 'June',
    'jul': 'July',
    'aug': 'August',
    'sep': 'September',
    'oct': 'October',
    'nov': 'November',
    'dec': 'December'
  };

  return map[month.toLowerCase()];
}

const Archive = ({ data }) => {
  const slugs = generateMonthYearSlugs(new Date(2020, 2), new Date());
  const groupedSlugs = groupSlugsByYear(slugs);

  const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a deveoloper"
  const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''
  return (
    <Layout page="time" >
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
       <Container>
          <Row>
              <Col xs={12}>
                  <Breadcrumb />
                  <div dangerouslySetInnerHTML={{__html: data.wpPage.content}}></div>
                  {Object.keys(groupedSlugs).sort((a, b) => b - a).map(year => (
                    <div key={year}>
                      <h2 className="mb-2"><Link to={`/archive/${year}`}>{year}</Link></h2>
                      <ul>
                        {groupedSlugs[year].sort((a, b) => new Date(year, new Date(0).getMonth(b.month)) - new Date(year, new Date(0).getMonth(a.month))).map(({ slug, month }) => (
                          <li key={slug}>
                            <Link to={`/archive/${slug}`}>{shortMonthToLong(month)}</Link>
                          </li>
                        ))}
                      </ul>                          
                      </div>
                  ))}
              </Col>
          </Row>    
      </Container>
    </Layout>
  )
}

export default Archive


export const pageQuery = graphql`
  query wpTimeQuery {
    wpPage(title: {eq: "ARCHIVE"}) {
      metadata {
        metadataTitle
        metadataImage {
          localFile {
            url
          }
        }
        metadataDescription
      }
      title
      content
    }
  }`